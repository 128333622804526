import { IoMdClose } from "react-icons/io";

export const Privacy = ({ showPrivacy, setShowPrivacy }) => {
  return (
    showPrivacy && (
      <div className="fixed inset-0 z-50 overflow-y-auto bg-white">
        <div className="flex flex-col items-center justify-center min-h-screen mx-auto max-w-[1400px] relative pt-24 px-4">
          <button
            onClick={() => setShowPrivacy(false)}
            className="absolute z-50 top-[1%] right-10 rounded-md bg-white"
          >
            <IoMdClose size={40} />
          </button>
          <h1 className="text-3xl mb-6 font-semibold">
            Общи Условия и Политика
          </h1>
          <div className="max-w-2xl mx-auto">
            <p>
              Общи Условия "АЛКОНА-СОЛУШЪНС" ООД е дружество, регистрирано по
              Търговския закон на Република България, със седалище и адрес на
              управление: Варна 9000, Алгикина Поляна 51, ВНИМАНИЕ!
              Осъществявайки достъп до и използвайки уебсайта на АЛКОНА СОЛУШЪНС
              ООД (alconasolutions.com) вие приемате, без ограничения и
              безусловно, тези Условия за ползване. Собственост над съдържанието
              Съдържанието на уебсайта изразено в текст и изображения е със
              защитени авторски права. Всички права, дори и тези, които изрично
              не са упоменати тук, са запазени. Използването на каквото и да е
              съдържание освен това, което не е в разрез с тези Условия за
              ползване, без писменото разрешение на АЛКОНА-СОЛУШЪНС ООД, е
              изрично забранено. Използване на Уебсайта Забранено е копирането,
              предаването, разпространението, съхраняването или по какъвто и да
              е друг начин използването на част или цялото Съдържание в каквато
              и да било форма без изричното писмено разрешение на АЛКОНА
              СОЛУШЪНС ООД; Забранено е използването на уебсайта за изпращане
              или предаване каквито и да са материали с противозаконно,
              заплашително, невярно, подвеждащо, оскърбително, малтретиращо,
              опозоряващо, оклеветяващо, вулгарно, неприлично, скандално,
              подстрекаващо, порнографско или религиозно некоректно съдържание
              или каквито и да са материали, които установяват или окуражават
              поведение, което би било сметнато за криминално престъпление, би
              довело до гражданска или наказателна отговорност или по друг начин
              би нарушило закона. Защита на личните данни. Kонфиденциална
              политика на АЛКОНА-СОЛУШЪНС ООД Съобразно Закона за защита на
              личните данни, Потребителят има право на достъп до собствените си
              Лични данни, които е въвел или са станали достояние на
              АЛКОНА-СОЛУШЪНС ООД при ползване от него на Виртуален каталог,
              както и на поправка на тези Лични данни. Приемайки настоящите
              условия Потребителите, които са регистрирали потребителско име и
              парола изрично се съгласяват предоставените от тях Лични данни да
              бъдат обработвани от АЛКОНА-СОЛУШЪНС ООД по електронен път при
              доставка на поръчани стоки или услуги и при необходимост от помощ
              от служител на АЛКОНА-СОЛУШЪНС ООД. АЛКОНА-СОЛУШЪНС ООД изрично
              уведомява, че предоставените от Потребителя Лични данни не се
              отстъпват на трети лица за рекламни и промоционални цели.
              АЛКОНА-СОЛУШЪНС ООД не носи отговорност за каквито и да е щети и
              загуби, възникнали поради неспазване на разпоредбите на настоящата
              точка. Цени АЛКОНА-СОЛУШЪНС ООД има правото да променя цените по
              свое усмотрение, по всяко време без да дължи предварително
              уведомяване на Потребителите. Потребителят е длъжен да заплати
              цената която е била посочена в уебсайта по време на правене на
              поръчката, независимо дали е по-ниска или по-висока от настоящата.
              При допуснати технически грешки в публикуването на Виртуален
              магазин, АЛКОНА-СОЛУШЪНС ООД има правото да откаже изпълнението на
              поръчката и не дължи обезщетение по никакъв начин на Потребителя,
              освен да възстанови сумите платени и/или депозирани от
              потребителя, ако има такива. Плащане на закупени стоки и услуги
              Уебсайта предлага възможност за избор за плащане на поръчаната
              стока чрез: Плащане на проформа фактура. Отказ от закупена стока
              Поръчаната стока не подлежи на връщане, ако вече е напуснала
              склада на АЛКОНА-СОЛУШЪНС ООД. Поръчки, които не са напуснали
              склада могат да бъдат анулирани чрез мейл или телефонно обаждане.
              При констатация на несъответствие на стоката с основните
              характеристики посочени в страницата, АЛКОНА-СОЛУШЪНС ООД
              възстановява депозираната за стоката от потребителя сума,
              включително и транспортните разходи начислени за придобиване на
              стоката съобразно чл. 59 ал.3 от ЗЗП и в срок от 7 дни от
              получаване на стоката, която трябва да е в ненарушен търговски
              вид, и оригинална опаковката. При констатация на дефектна стока,
              АЛКОНА-СОЛУШЪНС ООД се ангажира тя да бъде подменена с нова
              съгласно гаранционните условия на съответната фирма производител.
              Транспортните и други разходи по получаване и връщане на стоката
              са за сметка на АЛКОНА-СОЛУШЪНС ООД. При отказ от покупката
              съгласно чл.55 от ЗЗП, АЛКОНА-СОЛУШЪНС ООД възстановява
              депозираната за стоката от потребителя сума до 30 дни, считано от
              датата, на която потребителят е упражнил правото си на отказ от
              сключения договор и в случай, че стоката е върната в склада на
              АЛКОНА-СОЛУШЪНС ООД Стоката следва да бъде в търговски вид и
              ненарушена, оригинална опаковка. Транспортните разходи са за
              сметка на Потребителя. Други АЛКОНА-СОЛУШЪНС ООД има право да
              променя технологията и дизайна на предоставяните Услуги без
              предварително известяване. АЛКОНА-СОЛУШЪНС ООД си запазва правото
              да изпраща на своите потребители съобщения, свързани с нови услуги
              или с промени в условията и начините за използване на сегашните
              услуги. АЛКОНА-СОЛУШЪНС ООД управлява този Сайт от офисът си във
              Варна . АЛКОНА-СОЛУШЪНС ООД не декларира, че материалите или
              услугите в този Сайт са подходящи или достъпни извън България и
              съответно достъпът до тях от територии, където съдържанието им е
              незаконно, не се разрешава. Настоящите условия могат да бъдат
              актуализирани по всяко време без специално уведомяване на
              Потребителите. АЛКОНА-СОЛУШЪНС ООД не носи отговорност, ако
              Потребителят не е прочел последния вариант на настоящите условия.
              Отговорност АЛКОНА-СОЛУШЪНС ООД се стреми да поддържа актуална
              информация от източници, които счита за достоверни, но не
              гарантира, че те са такива, както и че страницата не съдържа
              грешки, и че достъпът до нея е непрекъсваем. АЛКОНА-СОЛУШЪНС ООД
              не потвърждава и не носи отговорност за информацията, съдържаща се
              в уебсайтове, към които има хипервръзки, както и за предложените
              продукти, услуги и други такива в същите уебсайтове.
              АЛКОНА-СОЛУШЪНС ООД не преглежда съдържанието на материали,
              предоставени и/или изпратени от потребители на нейния уебсайт, и
              не носи отговорност за такова съдържание. АЛКОНА-СОЛУШЪНС ООД може
              по всяко време по своя преценка да премахне всеки материал,
              изпратен от потребители. АЛКОНА-СОЛУШЪНС ООД не носи отговорност
              за качеството и състоянието на връзките към Интернет, както и за
              изправността на устройствата, които ги осигуряват, от Вашия
              компютър до сървъра, върху който е разположен уебсайта.
              АЛКОНА-СОЛУШЪНС ООД не носи отговорност за каквито и да е загуби -
              преки, непреки или последващи вреди, пропуснати ползи, произтичащи
              от или свързани с достъпа ви до ползването на уебсайта. Политика
              за осигуряване на защита на данните Сигурността при въвеждане и
              пренос на картовите данни се осигурява чрез използване на SSL
              протокол за криптиране на връзката между нашия сървър и платежната
              страница на обслужващата ни банка Връзки към уебсайтове на трети
              лица Уебсайтът може да съдържа връзки към уебсайтове, които са
              собственост или се оперират от трети страни, не от АЛКОНА-СОЛУШЪНС
              ООД. Тези връзки са предоставени само за ваше удобство.
              АЛКОНА-СОЛУШЪНС ООД няма контрол и не носи отговорност за
              съдържанието, политиките за защита на личната информация или
              сигурността на такива сайтове. Приложимо право За всички неуредени
              в тези условия въпроси се прилагат разпоредбите на действащото
              българско законодателство. Всички спорове, отнасящи се до тези
              Условия, които страните не са успели да решат чрез преговори и по
              взаимно съгласие, се отнасят за решаване пред компетентните
              български съдилища. Координатите на АЛКОНА-СОЛУШЪНС ООД са
              следните: Гр. Варна 9000, Ул. Агликина Поляна 51
            </p>
            <p className="my-10">
              Тази Политика обяснява как ние събираме и обработваме Вашите
              данни, когато използвате всеки от нашите уебсайтове
              alconasolutions.com. За Вас е важно да я познавате. Вашите
              отговорности: Предоставяйки ни данни, Вие ни декларирате, че сте
              на възраст над 16 /шестнадесет/ години. (Други Ваши отговорности –
              линк към следната информация: Вашите отговорности: • Прочетете
              тази Политика и я проверявайте редовно. • Ако сте наш клиент,
              моля, проверете свързаните с договорите между нас условия и/или
              политики: те могат да съдържат допълнителни подробности за това
              как събираме и обработваме данните Ви. • Обърнете внимание и на
              предоставяната от нас допълнителна информация и условия към
              различните услуги на уебсайта /контактна форма, регистрационна
              форма и други/. • Ако ни предоставите лична информация за други
              хора или ако други ни предоставят информацията Ви, ще използваме
              само тази информация за конкретната цел, поради която тя ни е била
              предоставена. С изпращането на информацията потвърждавате, че
              имате правото да ни упълномощите да я обработваме от ваше име в
              съответствие с тази Политика). • Предоставяйки ни данни, Вие ни
              декларирате, че сте на възраст над 16 /шестнадесет/ години.) I.
              Кой е отговорен за обработването на личните Ви данни? Ние,
              АЛКОНА-СОЛУШЪНС ООД, сме Администратор по отношение на Вашите
              лични данни и носим отговорност за тях. Ако имате някакви въпроси
              за Политиката или Вашите лични данни, моля, свържете се с нас.
              Данни за контакт с нас: АЛКОНА-СОЛУЩЪНС ЕООД, адрес: гр. Варна,
              Ул. Агликина Поляна 51, E-mail: office@alconasolutions.com Много
              важно е информацията, която имаме за Вас, да бъде вярна и
              актуална. Моля, информирайте ни при промени или ако забележите
              грешки. Изпратете ни имейл за това на move
              office@alconasolutions.com или сами актуализирайте данните си,
              където вече сте ги въвели. II. КАКВИ ДАННИ ЗА ВАС ОБРАБОТВАМЕ? Вие
              може да получите достъп до уебсайта, без да въвеждате лични данни
              (например име, адрес или адрес на ел. поща). Но и в този случай
              трябва да обработим определена информация, за да Ви дадем достъп
              до уебсайт. 1. Лог файлове /файлове за събития/: Когато посещавате
              уебсайта, нашият уеб сървър автоматично съхранява името на домейна
              или IP адреса на запитващия компютър (обикновено това е компютър
              на Вашия доставчик на интернет достъп), включително датата, часа и
              продължителността на посещението Ви, посетените подстраници/URL
              адреси и информация за приложението(-ята) и терминала(-ите), които
              използвате, за да разгледате нашите страници. 2. Бисквитки
              (Cookies): За да направим уебсайта ни възможно най-лесен за
              ползване от потребителите, ние използваме т. нар. „бисквитки”,
              подобно на много други оператори на уебсайтове. „Бисквитките” са
              малки текстови файлове, които се съхраняват в браузъра Ви.
              Повечето „бисквитки”, които използваме, са сесийни бисквитки. Те
              се изтриват автоматично в края на посещението Ви. Използваме обаче
              и постоянни „бисквитки”. Те служат за подобряване насочването на
              потребителите. Нашите „бисквитки” не събират никакви лични данни и
              не подпомагат идентифицирането Ви на уебсайтове на трети лица.
              Можете да настроите браузъра си така, че да Ви информира за
              създаването на „бисквитки”, така че тяхното използване да е
              напълно прозрачно за Вас. По принцип можете също да откажете
              приемането на бисквитки чрез настройките на браузъра си. Това
              обаче може да означава, че може да не сте в състояние да
              използвате всички функции на уебсайта. 3. Анализ на уебсайта чрез
              Google Analytics: За да подобряваме непрекъснато нашия уебсайт,
              ние използваме Google Analytics, услуга за уеб анализ на Google
              Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, САЩ
              („Google”). Google Analytics използва „бисквитки”, които се
              съхраняват на компютъра Ви и позволяват анализ на използването на
              уебсайта. Информацията, генерирана от „бисквитките” за
              използването Ви на този уебсайт, обикновено се изпраща на сървър
              на Google в Европа (или в държава-членка на Европейското
              икономическо пространство) за анонимизиране на IP адреса, така че
              се изключват всички лични характеристики. Едва след
              анонимизирането му, съкратеният IP адрес се прехвърля на сървър на
              Google в САЩ и се съхранява там. Само в изключителни случаи се
              налага прехвърляне и съхранение на пълния IP адрес на сървър на
              Google в САЩ. Този уебсайт използва Google Analytics с разширение
              за анонимно събиране на IP адреси (така нареченото IP маскиране).
              От наше име Google ще използва събраната информация за оценка
              използването на уебсайта, съставяне на отчети за дейността на
              уебсайта и предоставяне на други услуги, свързани с използването
              на уебсайта. IP адресът, предаван от Вашия браузър в контекста на
              Google Analytics, не се комбинира с други данни на Google. Можете
              да откажете използването на „бисквитки”, като изберете подходящите
              настройки в браузъра си. Бихме искали все пак да посочим, че в
              този случай е възможно да не можете да използвате изцяло всички
              функции на уебсайта. Можете също така да откажете Google да събира
              данните, генерирани от „бисквитки”, свързани с използването на
              уебсайта (включително IP адреса Ви) и обработва тези данни, като
              изтеглите и инсталирате добавката за бразуър, достъпна от следната
              връзка: https://tools.google.com/dlpage/gaoptout?hl=en Създава се
              „бисквитка” за отказ, която предотвратява събирането на данните
              Ви, когато посещавате този уебсайт в бъдеще. Допълнителна
              информация за защитата на данни в Google Analytics можете да
              намерите на https://www.google.com/analytics/terms/bg.html и на
              https://support.google.com/analytics/answer/6004245?hl=en. 4.
              Социалнa приставка /Social Plug-in/ Facebook: В нашия уебсайт сме
              включили бутон на социалната мрежа Facebook (1601 South California
              Avenue, Palo Alto, CA 94304, USA - www.facebook.com). Можете да
              разпознаете бутоните Facebook чрез логото на Facebook или бутона
              „Харесване”. Като оператор на този уебсайт, ние не разполагаме с
              информация относно съдържанието на предаваните данни или
              използването им от Facebook. Също така не знаем кога сте кликнали
              върху който и да е бутон. За повече информация относно начина, по
              който Facebook използва информацията Ви, моля, вижте Декларацията
              за поверителност на Facebook https://www.facebook.com/policy.php#
              5. Лични данни във връзка с взаимодействието Ви с нас • Ние
              предлагаме различни опции за информация и консултация чрез
              формуляра за контакт на нашия уебсайт. В зависимост от опцията,
              която сте избрали, обработваме различни лични данни, като: Вашето
              име и фамилия, физически адрес, e-mail адрес, телефон, информация
              за самата комуникацията и взаимодействието с нас. • Информация
              чрез поща/ел. Поща • Ако сте поискали да Ви бъде изпратен
              информационен материал, ние ще обработим предоставените от Вас
              данни във формуляра за контакт, като това обработване ще включва
              най-малко Вашето име и фамилия и подробностите за избрания от Вас
              начин за контакт (поща и/или електронна поща) за еднократно
              изпращане на материала, който сте поискали. • Консултация по
              телефона • Ако сте поискали консултация по телефона, ние ще
              обработим данните, предоставени от Вас във формуляра за контакт,
              които включват най-малко Вашето име и фамилия и телефонен номер,
              за да проведем поисканата от Вас консултация по телефона.
              Проведените с нас разговори по телефон по начало не се записват,
              но ако предприемем такава практика, надлежно ще ви уведомим за нея
              не по-късно от началото на телефонния разговор. • Лични
              консултации • Ако сте поискали лична консултация, ние ще обработим
              данните, предоставени от Вас във формуляра за контакт, които
              включват най-малко Вашето име и фамилия, телефонен номер и/или
              адрес на електронна поща, за да се свържем с Вас и да уговорим
              лична консултация. 6. Лични данни, събирани при онлайн поръчки
              Можете да направите поръчка на нашия уебсайт, без да се
              регистрирате и да създавате профил. В този случай ще поискаме от
              Вас и ще обработим предоставената информация, необходима за
              обработване на поръчката (напр. име и фамилия, адрес за
              фактуриране или друг адрес за доставка, адрес на електронна поща,
              условия за доставка и информация за поръчката, информация във
              връзка с монтажа). 7. Регистрационни и профилни данни. Когато се
              регистрирате като потребител, се създава Ваш профил. Ние събираме
              и обработваме данните, посочени в регистрационния формуляр на
              нашия уебсайт. Възможно е да поискаме повторно въвеждане на данни,
              които вече са събрани по време на регистрацията (по-конкретно
              данни за Вашата идентификация) и да обработим допълнителни лични
              данни, ако желаете да използвате определено съдържание на нашия
              уебсайт или да направите поръчка. Във вашия профил се запазва
              информация за направените от вас поръчки и извършени доставки и
              евентуално предоставени допълнителни услуги, както и друга
              информация, свързана с взаимодействието Ви с нас и активността Ви
              на уебсайта. Ние не събираме и не обработваме чувствителни данни
              за Вас /специални категории данни/. III. ЗА КАКВИ ЦЕЛИ И НА КАКВО
              ПРАВНО ОСНОВАНИЕ ОБРАБОТВАМЕ ВАШИТЕ ЛИЧНИ ДАННИ? Ние обработваме
              само лични данни, по вид и в обем, които са подходящи, свързани с
              и ограничени до необходимото за постигане на съответната цел,
              както следва: А. На основание нашите „легитимни интереси” за
              постигането на следните цели: 1. управление и развитие на нашите
              дейности във връзка с уебсайта и предлаганите от нас услуги и
              стоки /включително: да управляваме риска и сигурността, да
              анализираме използването на уебсайта и други онлайн услуги, да
              улесним ползването на сайта и да го подобряваме, да предоставим
              подходящо съдържание и реклами на уебсайта, да разберем
              ефективността на нашето съдържание и реклами/. 2. контрол и
              подобряване на дейността ни във връзка с обслужването на настоящи
              и потенциални клиенти и ползватели на сайта. 3. да Ви предоставим
              искана от вас информация и консултации. 4. да Ви предоставим
              исканите от вас услуги. 5. да осъществяваме контакт с Вас. 6. да
              защитим правата и законните си интереси в случай на съдебен и
              извънсъдебен спор. 7. за администриране на дейността и отчитане
              пред компании от групата. 8. да защитим нашите права на
              собственост и за предотвратяване на кражби и други престъпления.
              Б. На договорно или преддоговорно основание за постигането на
              следните цели: 1. да предприемем необходими стъпки, по Ваше
              искане, за сключване на договор помежду ни. 2. да изпълним сключен
              между нас договор. В. На основание наши законови задължения,
              например, за да спазим изискванията на данъчното и счетоводното
              законодателство във връзка с осъществяване на търговска дейност.
              Г. На основание дадено от Вас конкретно съгласие, например, за
              целите на директния маркетинг. Когато се изисква да събираме лични
              данни по закон или съгласно условията на договора между нас и Вие
              не ни предоставяте тези данни при поискване, може да не успеем да
              изпълним договора (например, да ви доставим стоки или услуги). Ако
              не ни предоставите исканите данни, може да се наложи да отменим
              поръчания от Вас продукт или услуга, но ако го направим, ще ви
              уведомим. Ще използваме Вашите лични данни само за целта, за която
              са били събрани, или ако е необходимо, за съвместима цел. За
              повече информация, моля пишете ни на office@alconasolution.com. В
              случай че трябва да използваме Вашите данни за несвързана нова
              цел, ние ще Ви уведомим и ще обясним правните основания за
              обработване. Ние може да обработваме личните Ви данни без Ваше
              знание или съгласие, когато това се изисква или разрешава от
              закона. IV. КАК СЪБИРАМЕ ВАШИТЕ ЛИЧНИ ДАННИ? От първия момент,
              когато взаимодействате с нашия уебсайт ние събираме данни.
              Понякога Вие ни предоставяте данни, понякога данните за вас се
              събират автоматично, например Лог файлове и бисквитки (Cookies)
              /моля, запознайте се с нашата Политика за бисквитките. Съответно
              Вие ни предоставяте Вашите лични данни при взаимодействието ни във
              физическите ни магазини. Може също да получаваме информация от
              публични източници, като Търговския регистър, например. V.
              МАРКЕТИНГ КОМУНИКАЦИИ Нашето правно основание за обработване на
              Вашите лични данни, за да ви изпратим маркетингови съобщения, е
              вашето съгласие или законните ни интереси (а именно да развиваме
              дейността си). И в двата случая Вие имате право, по всяко време,
              да се откажете от получаване на маркетингови съобщения. Преди да
              споделяме Вашите лични данни с която и да е трета страна за нейни
              маркетингови цели, ние ще поискаме Вашето съгласие. Можете да
              заявите от нас или от трети страни отказ от изпращане на
              маркетингови съобщения по всяко време като следвате връзките за
              отказ от всяко изпратено до вас съобщение за маркетинг (много
              често бутонът ще бъде на английски и ще пише „Unsubscribe”), ИЛИ,
              като ни изпратите отказа си по имейл на office@alconasolutions.com
              по всяко време. Ако се откажете от получаване на маркетингови
              съобщения, този отказ не се отнася за лични данни, предоставени в
              резултат на други транзакции, като покупки, гаранционни записи и
              др. VI. СПОДЕЛЯНЕ НА ВАШИТЕ ЛИЧНИ ДАННИ. Може да се наложи да
              споделяме вашите лични данни със страните, посочени по-долу: •
              Други компании в нашата група • Доставчици на услуги, които
              предоставят IT услуги и системна администрация. • Професионални
              консултанти, включително адвокати, банкери, одитори и
              застрахователи. • Публични органи, които изискват от нас
              деклариране на дейности по обработване. • Куриерски и транспортни
              фирми за извършване на доставка. • Фирми или лица, извършващи
              дейност по ремонт/поправка и обслужване на гаранции на стоките. •
              Фирми или лица – наши партньори, извършващи монтаж на нашите
              стоки. • Трети страни, на/с които продаваме, прехвърляме или
              обединяваме части от нашия бизнес или нашите активи. Ние изискваме
              от всички трети страни, на които прехвърляме вашите данни, да
              спазват сигурността на Вашите лични данни и да ги третират в
              съответствие със закона. Ние разрешаваме на трети страни да
              обработват вашите лични данни само за определени цели и в
              съответствие с нашите указания. VII. МЕЖДУНАРОДНО ПРЕДАВАНЕ НА
              ДАННИ Личните данни се обработват обикновено в рамките на
              Европейския съюз или на Европейското икономическо пространство;
              ние, по принцип, не възнамеряваме да прехвърляме лични данни в
              други държави („трети държави”). Информацията може да бъде
              прехвърляна на получатели в така наречените „трети държави” само
              във връзка с използването на доставчици на услуги за предоставяне
              на услуги за уеб анализ. „Трети държави” означава държави извън
              Европейския съюз или Споразумението за Европейското икономическо
              пространство, където не се гарантира равнище на защита на данните,
              сравнимо с това в Европейския съюз. Ако прехвърлената информация
              съдържа лични данни, ние гарантираме, че преди такова прехвърляне
              е осигурено необходимото ниво на защита на данните в съответната
              трета държава или при получателя в тази третата държава. Това може
              да се основава на решение на Европейската комисия относно
              адекватното ниво на защита на данните в определена трета държава
              като цяло. Като алтернатива, предаването на данни може да се
              основава на т. нар. „Стандартни договорни клаузи в ЕС”, договорени
              с получателя, или ако получателите са в САЩ – на базата на
              спазването на условията на програмата за защита на
              неприкосновеността на личния живот между САЩ и ЕС. Ще се радваме
              да Ви предоставим допълнителна информация за подходящите и уместни
              гаранции за поддържане на подходящо ниво на защита на данните при
              поискване. Информация за участниците в програмата за защита на
              неприкосновеността на личния живот между САЩ и ЕС можете да
              намерите на www.privacyshield.gov/list VIII. СИГУРНОСТ НА
              ИНФОРМАЦИЯТА Ние сме въвели мерки за сигурност, за да предотвратим
              случайно изгубване, използване, промяна, разкриване или достъп до
              вашите лични данни без разрешение. Ние също така предоставяме
              достъп до Вашите лични данни само на тези служители и партньори,
              които имат бизнес необходимост да имат достъп до такива данни
              Разполагаме с процедури за справяне с всяко съмнение за нарушение
              на лични данни и ще уведомим Вас и всеки компетентен Надзорен
              орган за нарушение, ако това е необходимо. Във всички случаи
              следва да знаете, че винаги съществува риск за Вашите лични данни,
              особено когато обработването става през Интернет. IX. СРОК ЗА
              СЪХРАНЕНИЕ НА ДАННИТЕ Ще запазим Вашите лични данни само толкова
              дълго, колкото е необходимо, за да изпълним целите, за които сме
              ги събрали, включително за целите на спазване на законови,
              счетоводни, данъчни или отчетни изисквания. Когато решаваме какъв
              е точният срок за съхранение на данните, ние вземаме предвид
              техния обем, естеството и чувствителността, потенциалния риск от
              вреди от неразрешено използване или разкриване, целите на
              обработване, ако те могат да бъдат постигнати чрез други средства
              и законови изисквания. За данъчни цели законът изисква от нас да
              съхраняваме основна информация за нашите клиенти (включително
              данни за контакти, идентичност, финансови данни и данни за
              транзакции) в продължение на шест години, след като престанете да
              бъдат наши клиенти. При някои обстоятелства може да анонимизираме
              Вашите лични данни за изследователски или статистически цели, в
              който случай можем да използваме тази информация за неограничен
              период от време без допълнително уведомление до Вас. X. ВАШИТЕ
              ПРАВА При определени обстоятелства, по закон, имате право да: •
              Поискате достъп до личната Ви информация (обикновено наричано
              "заявка за достъп от субекта на данните"). Това ви дава възможност
              да получите копие от личната информация, която съхраняваме за вас,
              и да проверите дали ние я обработваме законосъобразно. • Поискате
              корекция на личната информация, която държим за Вас. Това ви
              позволява да коригирате и актуализирате непълна или неточна
              информация, която държим за Вас. • Поискате изтриване на Вашата
              лична информация. Това ви дава възможност да ни помолите да
              изтрием или премахнем личната информация, когато не е налице
              основателна причина да продължим да я обработваме. Имате право да
              поискате от нас да изтрием или премахнем Вашата лична информация и
              в случая, когато сте упражнили правото си да възразите срещу
              обработването (вижте по-долу). • Възразите срещу обработването на
              личните Ви данни, когато основаваме обработването на наш легитимен
              интерес (или такъв на трета страна) и са налице основания,
              свързани Вашата конкретна ситуация. Освен това, имате право да
              възразите, когато обработваме вашата лична информация за целите на
              директния маркетинг. • Поискате ограничаване на обработването на
              личната Ви информация. Това ви позволява да ни помолите да спрем
              обработването на лична информация за Вас, например ако искате да
              установим точността или основанието за нейното обработване. •
              Поискате прехвърляне на Вашата лична информация на друга страна
              /право на преносимост/. Ако искате да упражните някое от горните
              Ви права, моля, свържете се с нас на office@alconasolutions.com
              Няма да плащате такса за достъп до личните ви данни (или за
              упражняване на някое от другите права). Въпреки това, може да
              наложим разумна такса, ако молбата ви е очевидно неоснователна,
              повтаряща се или прекомерна, или ще откажем да изпълним молбата Ви
              при тези обстоятелства. Може да се наложи да поискаме от вас
              конкретна информация, за да ни помогнете да потвърдим
              самоличността Ви и да гарантираме Вашето право на достъп до вашите
              лични данни (или да упражнявате някое от другите ви права). Това е
              мярка за сигурност, която гарантира, че личните данни не се
              разкриват на лице, което няма право да го получи. Също така може
              да се свържем с Вас, за да Ви поискаме допълнителна информация във
              връзка с Вашето искане за ускоряване на отговора. Опитваме се да
              отговорим на всички легитимни искания в рамките на един месец.
              Понякога може да ни отнеме повече от месец, ако искането ви е
              особено сложно или сте направили няколко искания. В този случай ще
              Ви уведомим. Ако не сте доволни от каквито и да било аспекти на
              това как събираме и използваме вашите данни, имате право да
              подадете жалба до Комисията за защита на личните данни -
              Надзорният орган на Република България, определена със Закона за
              защита на личните данни, както следва: Адрес: София 1592, бул.
              „Проф. Цветан Лазаров” № 2 Карта. GPS координати: N 42.668839 E
              23.377495. Център за информация и контакти - тел. 02/91-53-518.
              Електронна поща: kzld@cpdp.bg. Интернет страница: www.cpdp.bg Ще
              Ви бъдем благодарни, ако първо се свържете с нас, в случай на
              оплакване, за да можем да се опитаме да Ви помогнем.
            </p>
          </div>
        </div>
      </div>
    )
  );
};
