const data = {
  66: {
    62.0: 206.99 / 1.2,
    77.5: 219.99 / 1.2,
    93.0: 232.99 / 1.2,
    108.5: 241.99 / 1.2,
    124.0: 269.99 / 1.2,
    139.5: 277.99 / 1.2,
    155.0: 282.99 / 1.2,
    170.5: 293.99 / 1.2,
    186.0: 299.99 / 1.2,
  },
  76: {
    62.0: 215.99 / 1.2,
    77.5: 231.99 / 1.2,
    93.0: 246.99 / 1.2,
    108.5: 258.99 / 1.2,
    124.0: 288.99 / 1.2,
    139.5: 298.99 / 1.2,
    155.0: 305.99 / 1.2,
    170.5: 318.99 / 1.2,
    186.0: 328.99 / 1.2,
  },
  86: {
    62.0: 215.99 / 1.2,
    77.5: 231.99 / 1.2,
    93.0: 246.99 / 1.2,
    108.5: 258.99 / 1.2,
    124.0: 288.99 / 1.2,
    139.5: 298.99 / 1.2,
    155.0: 305.99 / 1.2,
    170.5: 318.99 / 1.2,
    186.0: 328.99 / 1.2,
  },
  96: {
    62.0: 231.99 / 1.2,
    77.5: 249.99 / 1.2,
    93.0: 265.99 / 1.2,
    108.5: 279.99 / 1.2,
    124.0: 312.99 / 1.2,
    139.5: 324.99 / 1.2,
    155.0: 333.99 / 1.2,
    170.5: 347.99 / 1.2,
    186.0: 359.99 / 1.2,
  },
  106: {
    62.0: 224.99 / 1.2,
    77.5: 243.99 / 1.2,
    93.0: 259.99 / 1.2,
    108.5: 273.99 / 1.2,
    124.0: 308.99 / 1.2,
    139.5: 325.99 / 1.2,
    155.0: 339.99 / 1.2,
    170.5: 359.99 / 1.2,
    186.0: 376.99 / 1.2,
  },
  116: {
    62.0: 224.99 / 1.2,
    77.5: 243.99 / 1.2,
    93.0: 259.99 / 1.2,
    108.5: 273.99 / 1.2,
    124.0: 308.99 / 1.2,
    139.5: 325.99 / 1.2,
    155.0: 339.99 / 1.2,
    170.5: 359.99 / 1.2,
    186.0: 376.99 / 1.2,
  },
  126: {
    62.0: 233.99 / 1.2,
    77.5: 243.99 / 1.2,
    93.0: 273.99 / 1.2,
    108.5: 283.99 / 1.2,
    124.0: 319.99 / 1.2,
    139.5: 337.99 / 1.2,
    155.0: 351.99 / 1.2,
    170.5: 372.99 / 1.2,
    186.0: 392.99 / 1.2,
  },
  146: {
    62.0: 243.99 / 1.2,
    77.5: 266.99 / 1.2,
    93.0: 287.99 / 1.2,
    108.5: 300.99 / 1.2,
    124.0: 338.99 / 1.2,
    139.5: 357.99 / 1.2,
    155.0: 372.99 / 1.2,
    170.5: 394.99 / 1.2,
    186.0: 413.99 / 1.2,
  },
  166: {
    62.0: 252.99 / 1.2,
    77.5: 278.99 / 1.2,
    93.0: 301.99 / 1.2,
    108.5: 317.99 / 1.2,
    124.0: 357.99 / 1.2,
    139.5: 377.99 / 1.2,
    155.0: 393.99 / 1.2,
    170.5: 416.99 / 1.2,
    186.0: 436.99 / 1.2,
  },
  186: {
    62.0: 258.99 / 1.2,
    77.5: 286.99 / 1.2,
    93.0: 311.99 / 1.2,
    108.5: 333.99 / 1.2,
    124.0: 375.99 / 1.2,
    139.5: 396.99 / 1.2,
    155.0: 413.99 / 1.2,
    170.5: 438.99 / 1.2,
    186.0: 460.0 / 1.2,
  },
};

export default data;
